import Vue from 'vue';
import _ from 'lodash';

import {
  LOAD_LAYOUT,
  LOAD_LAYOUT_CHILDREN,
  SET_LAYOUT_CHILDREN,
  CREATE_LAYOUT,
  CLONE_LAYOUT,
  RESET_LAYOUT,
  SET_SAVE_LAYOUT,
  SET_SAVING_LAYOUT,

  // Widgets
  CREATE_LAYER,
  UPDATE_WIDGET_THUMBNAIL,
  CREATE_COMMON_WIDGET,
  LAYOUT_DESIGNER_ADD_WIDGET,
  REMOVE_DELETED_CHILDREN,
  REPLACE_DUMMMY_CHILD,
  LAYOUT_REMOVE_MODIFIED_WIDGET,
  LAYOUT_REPLACE_CHILD_ITEM,
  SET_SAVE_Z_INDEXES,

  // Playlists
  SET_PLAYLIST_CHILDREN,
  SET_PLAYLIST_UPDATED,
  UPDATE_PLAYLIST_ITEM,
  UPDATE_SAVED_PLAYLIST_ITEM,
  REMOVE_PLAYLIST_ITEM,
  REMOVE_FROM_PLAYLIST_ITEMS_CHANGES,

  // Dummy widgets
  CREATE_DUMMY_LAYER,
  LAYOUT_ADD_REPLACED_WIDGET,
  LAYOUT_DELETE_REPLACED_WIDGET,

  // internal to editor
  LAYOUT_DESIGNER_TO_SETTING_TAB,
  LAYOUT_DESIGNER_SET_LAYOUT,
  LAYOUT_DESIGNER_TOGGLE_GRID,
  LAYOUT_DESIGNER_TOGGLE_SNAP,

  // shared
  LAYOUT_DESIGNER_DELETE_WIDGET,
  LAYOUT_DESIGNER_DESELECT_WIDGET,
  LAYOUT_DESIGNER_UPDATE_WIDGET,

  // designer to editor
  LAYOUT_DESIGNER_SELECT_WIDGET,
  LAYOUT_DESIGNER_SET_ZINDEX,
  LAYOUT_ROTATION,
  SET_REFRESHING_LAYOUT_STATE,
  SET_GUIDE_LINES,
  LAYOUT_DESIGNER_DUPLICATE_WIDGET,
  SET_PLAYLIST_ITEMS_PANEL,
} from '@/store/actions/layoutDesigner';
import { SET_PLAYLIST_UPDATE_STATE } from '@/store/actions/player';

import { WIDGET_TYPES, LAYOUT_RESOLUTIONS, LAYOUT_ASPECT_RATIOS } from '@/models/layoutDesigner';

import {
  apiCreateLayout,
  apiAttachLayoutChild,
  apiGetLayoutChildren,
  apiReadLayout,
  apiCreateLayerWidget,
  apiCreateAppWidget,
} from '@/api/layouts';

import { arrayToObjectWithIdsAsKeys, getWidgetId, getWidgetItemType } from '@/helpers/utils';
import { generateWidgetId } from '@/models/layoutDesigner';

import { REFRESH_LAYOUT_STATUS } from '@/config/constants';
import { generateThumbnailDataUrl } from '@/models/layoutDesigner/konva';

const state = {
  // shared
  widgets: {},
  playlistsItems: {},
  playlistItemsChanges: {},
  deletedWidgets: {},
  modifiedWidgets: {},
  replacedWidgets: {},
  isWidgetSettingTab: true,

  // for editor
  selectedWidget: null,
  layout: null,
  playlistOnEditMode: false,

  // layouts page
  layouts: [],

  isLayoutHorizontal: false,
  refreshingLayoutStatus: REFRESH_LAYOUT_STATUS.HOLD,

  vLine: [],
  hLine: [],

  showLayoutGrid: false,
  isSnapGridActive: true,
  saveLayout: false,
  isSavingLayout: false,
};

const getters = {
  getPlaylistOnEditMode: (state) => {
    const playlistId = state.playlistOnEditMode;

    if (!playlistId) return null;

    const playlist = state.widgets[playlistId];

    return !!playlist ? playlist : null;
  },

  getPlaylistItems: (state) => (playlistWid) => {
    const playlistItems = state.playlistsItems[playlistWid];

    if (!playlistItems) return null;

    const sortedPlaylistItems = Object.values(playlistItems).sort(
      (item1, item2) => item1.item_priority - item2.item_priority,
    );

    return sortedPlaylistItems;
  },

  getPlaylistItemsToSave:
    (state) =>
    (playlistWid, action = 'update') => {
      const playlistItems = state.playlistItemsChanges[playlistWid];

      if (!playlistItems) return;

      const playlistItemsToSave = Object.values(playlistItems).filter(
        (item) => item.action === action,
      );

      return playlistItemsToSave;
    },

  getLayoutRotation: (state) => (state.isLayoutHorizontal ? 'horizontal' : 'vertical'),

  getAspectRatio: (state) => {
    const aspectRatio = state.layout ? state.layout.settings.aspectRatio.split(':') : ['16', '9'];

    return state.isLayoutHorizontal
      ? `${aspectRatio[0]} / ${aspectRatio[1]}`
      : `${aspectRatio[1]} / ${aspectRatio[0]}`;
  },

  getResolution: (state) => {
    const aspectRatio = state.layout?.settings.aspectRatio || LAYOUT_ASPECT_RATIOS.SIXTEEN_NINE;
    const resolution =
      state.layout?.settings.resolution || LAYOUT_RESOLUTIONS.defaultValues[aspectRatio];

    return LAYOUT_RESOLUTIONS.resolutions[aspectRatio][resolution];
  },

  getLayoutDimensions: (state, getters) => {
    const layoutRotation = getters.getLayoutRotation;
    const layoutResolution = getters.getResolution;

    return layoutResolution.layout[layoutRotation];
  },

  getScreenDimensions: (state, getters) => {
    const rotation = getters.getLayoutRotation;
    const { width, height } = getters.getResolution;

    return {
      width: rotation === 'horizontal' ? width : height,
      height: rotation === 'horizontal' ? height : width,
    };
  },

  getWidgetsByZIndex: (state, { getLayoutRotation }) => {
    return Object.values(state.widgets).sort(
      (widget1, widget2) =>
        widget1.position[getLayoutRotation].zIndex - widget2.position[getLayoutRotation].zIndex,
    );
  },

  widgetsByZIndex: (state, { getLayoutRotation }) => {
    return Object.values(state.widgets)
      .sort(
        (widget1, widget2) =>
          widget1.position[getLayoutRotation].zIndex - widget2.position[getLayoutRotation].zIndex,
      )
      .map((w) =>
        state.isLayoutHorizontal ? w.position.horizontal.zIndex : w.position.vertical.zIndex,
      );
  },
};

const actions = {
  [LOAD_LAYOUT]: async ({ commit, dispatch, state }, { layoutId, loadChildren = false }) => {
    try {
      const response = await apiReadLayout(layoutId);
      const layout = response.data;

      commit(LAYOUT_DESIGNER_SET_LAYOUT, { layout, saveLayout: false });

      if (loadChildren) {
        await dispatch(LOAD_LAYOUT_CHILDREN, layoutId);
      }

      return layout;
    } catch (error) {
      console.log('error: ', error);
      return false;
    }
  },

  [LOAD_LAYOUT_CHILDREN]: async ({ commit, getters }, layoutId) => {
    try {
      const response = await apiGetLayoutChildren(layoutId);

      const formatedWidgets = response.data.map((widget) => {
        const isAnApp = widget.object.item_type?.includes('app');

        let itemType = getWidgetItemType(widget);

        const layouObject = isAnApp ? { ...widget.object, ...widget.object.config } : widget.object;

        return {
          ...widget,
          assoc_id: widget.assoc_id,
          object: {
            ...layouObject,
            type: itemType,
            config: undefined,
            wid: generateWidgetId(),
          },
          itemType,
        };
      });

      // <-- Fixing z-indexes - START -->
      let updateZIndexes = false;
      const position = getters.getLayoutRotation;

      formatedWidgets
        .sort(
          (widget1, widget2) =>
            widget1.position[position].zIndex - widget2.position[position].zIndex,
        )
        .forEach((widget, index) => {
          if (widget.position[position].zIndex !== index) {
            updateZIndexes = true;
          }
        });
      // <-- Fixing z-indexes - END -->

      commit(SET_SAVE_Z_INDEXES, updateZIndexes);
      commit(SET_LAYOUT_CHILDREN, arrayToObjectWithIdsAsKeys(formatedWidgets, 'assoc_id'));

      return formatedWidgets;
    } catch (error) {
      console.log('error: ', error);
      return false;
    }
  },

  [CREATE_LAYOUT]: async (context, { layoutData }) => {
    try {
      const response = await apiCreateLayout(layoutData);

      return response.data;
    } catch (error) {
      console.log('error: ', error);
      return false;
    }
  },

  [CLONE_LAYOUT]: async ({ commit, dispatch }, { layout, widgets }) => {
    try {
      const layoutData = {
        ...layout.settings,
        name: `${layout.settings.name} copy`,
      };

      const response = await apiCreateLayout(layoutData);
      const newLayout = response.data;

      const createWidgetsPromises = [];
      const widgetsArray = Object.values(widgets);

      for (let i = 0; i < widgetsArray.length; i++) {
        const widget = widgetsArray[i];
        const widgetData = {
          type: widget.type,
          position: widget.position,
          id:
            widget.type === WIDGET_TYPES.PLAYLIST
              ? widget.object.playlist_id
              : widget.object.item_id || widget.object.template_id,
        };

        let attachApiCall;
        const appExtraField = {};

        switch (widget.itemType) {
          case WIDGET_TYPES.BUTTON:
            appExtraField.buttonText = widget.object.buttonText;
          case WIDGET_TYPES.TEXT:
          case WIDGET_TYPES.RICH_TEXT:
            appExtraField.userText = widget.object.userText;
          case WIDGET_TYPES.CLOCK:
            appExtraField.clockName = widget.object.clockName;
            const appData = {
              type: widget.itemType,
              name: widget.object.name,
              config: { ...widget.object, config: undefined, item_id: undefined },
              ...appExtraField,
            };

            attachApiCall = dispatch(CREATE_COMMON_WIDGET, {
              layoutId: newLayout.layout_id,
              widget,
              widgetData: appData,
              position: widget.position,
            });
            break;
          case WIDGET_TYPES.PDF:
          case WIDGET_TYPES.IMAGE:
          case WIDGET_TYPES.TEMPLATE:
          case WIDGET_TYPES.VIDEO:
          case WIDGET_TYPES.AUDIO:
          case WIDGET_TYPES.APP:
          case WIDGET_TYPES.PLAYLIST:
            attachApiCall = apiAttachLayoutChild({ layoutId: newLayout.layout_id, widgetData });
            break;

          case WIDGET_TYPES.LAYER:
            attachApiCall = dispatch(CREATE_LAYER, {
              layoutId: newLayout.layout_id,
              widget: { ...widget.object, position: widget.position },
            });

            break;
        }

        createWidgetsPromises.push(attachApiCall);
      }

      const data = await Promise.all(createWidgetsPromises);
      return newLayout;
    } catch (error) {
      console.log('error: ', error);
      return false;
    }
  },

  [LAYOUT_ROTATION]: async ({ state, commit }, isHorizontal) => {
    const layout = {
      ...state.layout,
      settings: { ...state.layout.settings, isHorizontal },
    };

    commit(LAYOUT_DESIGNER_DESELECT_WIDGET);
    commit(LAYOUT_DESIGNER_SET_LAYOUT, { layout });
  },

  [CREATE_LAYER]: async (context, { layoutId, widget }) => {
    try {
      const response = await apiCreateLayerWidget(widget);

      const widgetData = {
        type: WIDGET_TYPES.LAYOUT_ITEM,
        id: response.data.item_id,
        position: widget.position,
      };

      return await apiAttachLayoutChild({ layoutId, widgetData });
    } catch (error) {
      console.log('error: ', error);
    }
  },

  [CREATE_COMMON_WIDGET]: async (context, { layoutId, widgetData: appData, position }) => {
    try {
      const response = await apiCreateAppWidget(appData);

      const widgetData = {
        type: WIDGET_TYPES.LAYOUT_ITEM,
        id: response.data.item_id,
        position,
      };

      return await apiAttachLayoutChild({ layoutId, widgetData });
    } catch (error) {
      console.log('error: ', error);
    }
  },

  [UPDATE_WIDGET_THUMBNAIL]: ({ commit, state, getters }) => {
    try {
      const { layout } = state;
      const rotation = layout.settings.isHorizontal ? 'horizontal' : 'vertical';
      const thumbnailWidth = layout.settings.isHorizontal ? 160 : 90;
      const thumbnailHeight = layout.settings.isHorizontal ? 90 : 160;
      const widgetsForThumbnail = getters.getWidgetsByZIndex.map((widget) => ({
        ...widget.position[rotation],
      }));

      const newLayoutThumbnail = generateThumbnailDataUrl(
        widgetsForThumbnail,
        thumbnailWidth,
        thumbnailHeight,
        true,
      );

      const updatedLayout = {
        ...layout,
        settings: {
          ...layout.settings,
          image: newLayoutThumbnail,
        },
      };

      commit(LAYOUT_DESIGNER_SET_LAYOUT, { layout: updatedLayout, saveLayout: false });

      return newLayoutThumbnail;
    } catch (error) {
      console.log('error: ', error);
    }
  },

  [LAYOUT_REPLACE_CHILD_ITEM]: async ({ commit }, { widget, newItemId }) => {
    commit(LAYOUT_DESIGNER_UPDATE_WIDGET, { widget });

    if (widget.assoc_id) {
      commit(LAYOUT_ADD_REPLACED_WIDGET, { widget, newItemId });
    }
  },

  [CREATE_DUMMY_LAYER]: async ({ commit }, { widget }) => {
    const newWidget = {
      type: WIDGET_TYPES.LAYOUT_ITEM,
      object: { ...widget, position: undefined },
      position: widget.position,
      itemType: WIDGET_TYPES.LAYER,
    };

    commit(LAYOUT_DESIGNER_ADD_WIDGET, newWidget);
  },

  [SET_PLAYLIST_UPDATED]({ commit }, assocId) {
    if (assocId) {
      commit(SET_PLAYLIST_UPDATED, assocId);
    }

    commit(SET_PLAYLIST_UPDATE_STATE, true, { root: true });
    commit(SET_SAVE_LAYOUT);
  },
};

const mutations = {
  [RESET_LAYOUT]: (state) => {
    state.layout = null;

    state.widgets = {};
    state.deletedWidgets = {};
    state.modifiedWidgets = {};
    state.replacedWidgets = {};
    state.playlistsItems = {};
    state.playlistItemsChanges = {};
    state.selectedWidget = null;
    state.saveLayout = false;
    state.isSavingLayout = false;
    state.playlistOnEditMode = false;
  },

  [SET_LAYOUT_CHILDREN](state, widgets) {
    Vue.set(state, 'widgets', widgets);
  },

  [SET_SAVE_Z_INDEXES]: (state, status = true) => {
    Vue.set(state, 'fixZIndexes', status);
  },

  [SET_PLAYLIST_CHILDREN](state, { wid, playlistItems = {} }) {
    Vue.set(state.playlistsItems, wid, playlistItems);
  },

  // internal to editor
  [LAYOUT_DESIGNER_TO_SETTING_TAB](state, yes) {
    state.isWidgetSettingTab = yes;
  },

  [LAYOUT_DESIGNER_SET_LAYOUT](state, { layout, saveLayout = true }) {
    Vue.set(state, 'layout', { ...layout });

    state.isLayoutHorizontal = layout.settings.isHorizontal;

    if (saveLayout) this.commit(SET_SAVE_LAYOUT);
  },

  [LAYOUT_DESIGNER_TOGGLE_GRID](state, value = false) {
    state.showLayoutGrid = value;
  },

  [LAYOUT_DESIGNER_TOGGLE_SNAP](state, value = false) {
    state.isSnapGridActive = value;
  },

  // shared between editor and designer
  [LAYOUT_DESIGNER_ADD_WIDGET](state, widget) {
    const widgetId = getWidgetId(widget);

    Vue.set(state.widgets, widgetId, widget);

    if (!widget.assoc_id) Vue.set(state.modifiedWidgets, widgetId, 'new');

    this.commit(LAYOUT_DESIGNER_DESELECT_WIDGET);
    this.commit(SET_SAVE_LAYOUT);
  },

  [LAYOUT_DESIGNER_DUPLICATE_WIDGET](state, widget) {
    const widgetId = getWidgetId(widget);

    Vue.set(state.widgets, widgetId, widget);

    if (!widget.assoc_id) Vue.set(state.modifiedWidgets, widgetId, 'new');

    this.commit(LAYOUT_DESIGNER_SELECT_WIDGET, widget);
    this.commit(SET_SAVE_LAYOUT);
  },

  [LAYOUT_DESIGNER_DELETE_WIDGET](state, { widget, saveOnDeletedWidgets = false }) {
    if (!widget) return;

    const widgetId = getWidgetId(widget);

    if (saveOnDeletedWidgets) {
      Vue.set(state.deletedWidgets, widgetId, widget);
      Vue.set(state.modifiedWidgets, widgetId, 'deleted');
    } else {
      Vue.delete(state.modifiedWidgets, widgetId);
    }

    const { wid } = widget.object;

    if (widget.itemType === WIDGET_TYPES.PLAYLIST && !!state.playlistItemsChanges[wid]) {
      Vue.delete(state.playlistItemsChanges, wid);
    }

    if (widget.itemType === WIDGET_TYPES.PLAYLIST && !!state.playlistsItems[wid]) {
      Vue.delete(state.playlistsItems, wid);
    }

    Vue.delete(state.widgets, widgetId);

    this.commit(LAYOUT_DESIGNER_DESELECT_WIDGET);
    this.commit(SET_SAVE_LAYOUT);
  },

  [REMOVE_DELETED_CHILDREN](state, widgetId) {
    Vue.delete(state.deletedWidgets, widgetId);
    Vue.delete(state.modifiedWidgets, widgetId);
  },

  [LAYOUT_ADD_REPLACED_WIDGET](state, { widget, newItemId }) {
    Vue.set(state.replacedWidgets, widget.assoc_id, newItemId);
  },

  [LAYOUT_DELETE_REPLACED_WIDGET](state, widgetId) {
    Vue.delete(state.replacedWidgets, widgetId);
  },

  [REPLACE_DUMMMY_CHILD](state, { widget }) {
    const widgetId = state.widgets[widget.object.wid];

    if (widgetId) {
      Vue.delete(state.widgets, widget.object.wid);
      Vue.delete(state.modifiedWidgets, widget.object.wid);
    }

    this.commit(LAYOUT_DESIGNER_ADD_WIDGET, widget);
    this.commit(LAYOUT_DESIGNER_DESELECT_WIDGET);
  },

  [LAYOUT_REMOVE_MODIFIED_WIDGET](state, widgetId) {
    Vue.delete(state.modifiedWidgets, widgetId);
  },

  [LAYOUT_DESIGNER_UPDATE_WIDGET](state, { widget, selectWidget = true }) {
    const newWidget = { ...widget };
    const widgetId = getWidgetId(widget);
    const modifiedWidget = state.modifiedWidgets[widgetId];

    if (state.widgets[widgetId]) {
      Vue.set(state.widgets, widgetId, newWidget);
      Vue.set(state.modifiedWidgets, widgetId, modifiedWidget === 'new' ? 'new' : 'modified');
    }

    if (selectWidget) this.commit(LAYOUT_DESIGNER_SELECT_WIDGET, newWidget);

    this.commit(SET_SAVE_LAYOUT);
  },

  [UPDATE_PLAYLIST_ITEM](state, { playlistWid, playlistItem = {} }) {
    const { wid, assoc_id } = playlistItem;

    if (!state.playlistItemsChanges[playlistWid]) {
      Vue.set(state.playlistItemsChanges, playlistWid, {});
    }

    Vue.set(state.playlistItemsChanges[playlistWid], wid, {
      action: assoc_id ? 'update' : 'create',
      id: wid,
    });

    Vue.set(state.playlistsItems[playlistWid], wid, playlistItem);

    this.commit(SET_SAVE_LAYOUT);
  },

  [UPDATE_SAVED_PLAYLIST_ITEM](state, { playlistWid, playlistItem }) {
    const { wid } = playlistItem;

    Vue.set(state.playlistsItems[playlistWid], wid, playlistItem);

    if (state.playlistItemsChanges[playlistWid] && state.playlistItemsChanges[playlistWid][wid]) {
      Vue.delete(state.playlistItemsChanges[playlistWid], wid);
    }
  },

  [REMOVE_PLAYLIST_ITEM](state, { playlistWid, playlistItem }) {
    const { wid } = playlistItem;

    Vue.delete(state.playlistsItems[playlistWid], wid);

    if (!playlistItem.assoc_id && state.playlistItemsChanges[playlistWid][wid]) {
      Vue.delete(state.playlistItemsChanges[playlistWid], wid);
      return;
    }

    if (!state.playlistItemsChanges[playlistWid]) {
      Vue.set(state.playlistItemsChanges, playlistWid, {});
    }

    Vue.set(state.playlistItemsChanges[playlistWid], wid, {
      action: 'delete',
      id: wid,
      data: playlistItem,
    });
  },

  [REMOVE_FROM_PLAYLIST_ITEMS_CHANGES](state, playlistWid) {
    if (state.playlistItemsChanges[playlistWid]) {
      Vue.delete(state.playlistItemsChanges, playlistWid);
    }
  },

  // designer to editor
  [LAYOUT_DESIGNER_SET_ZINDEX](state, { widgetsToUpdate, widgetToSelect, selectWidget = false }) {
    widgetsToUpdate.forEach((widget) => {
      this.commit(LAYOUT_DESIGNER_UPDATE_WIDGET, { widget, selectWidget: false });
    });

    if (selectWidget) this.commit(LAYOUT_DESIGNER_SELECT_WIDGET, widgetToSelect);
  },

  [SET_PLAYLIST_UPDATED](state, assocId) {
    Vue.set(state.modifiedWidgets, assocId, 'modified');
  },

  [SET_SAVE_LAYOUT](state, value = true) {
    state.saveLayout = value;
  },

  [SET_SAVING_LAYOUT](state, value = true) {
    state.isSavingLayout = value;
  },

  [LAYOUT_DESIGNER_DESELECT_WIDGET](state) {
    state.selectedWidget = null;
    state.playlistOnEditMode = false;
  },

  [LAYOUT_DESIGNER_SELECT_WIDGET](state, widget) {
    state.selectedWidget = { ...widget };

    state.isWidgetSettingTab = true;
    state.playlistOnEditMode = false;
  },

  [SET_REFRESHING_LAYOUT_STATE](state, value = REFRESH_LAYOUT_STATUS.STAND_BY) {
    state.refreshingLayoutStatus = value;
  },

  [SET_PLAYLIST_ITEMS_PANEL](state, status = false) {
    state.playlistOnEditMode = status;
  },

  [SET_GUIDE_LINES](state, lines) {
    state.vLine = lines.vLine;
    state.hLine = lines.hLine;
  },
};

export default {
  getters,
  state,
  actions,
  mutations,
};
